<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec user-management"
    >
    <div class="packages-heading">
            <h2 class="mc-b-1">Powerful Links, Proven Results </h2>
            <p>Explore our bulk buying options with tailored pricing for brands, designed to<br>
              maximize your outreach and boost your SEO performance.</p>
        </div>
    <div class="table-responsive">
            <table class="table pricing-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Ascend</th>
                        <th>Pro</th>
                        <th>Boost</th>
                        <th>Amplify</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td>
                            <div class="pricing">
                                $2,599 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $4,999 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $7,999 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $15,999 <span>/month</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Links</td>
                        <td>8</td>
                        <td>16</td>
                        <td>27</td>
                        <td>58</td>
                    </tr>
                    <tr>
                        <td>Average DA</td>
                        <td>40+</td>
                        <td>40+</td>
                        <td>40+</td>
                        <td>40+</td>
                    </tr>
                    <tr>
                        <td>Can spread across multiple domains</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Authority Links Included</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Target Page Plan</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Anchor Text Optimization</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Competitor Backlink Gap Analysis</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Keyword Analysis</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Custom Reporting Dashboard</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Internal Linking Optimization</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Toxic Backlink Audit</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Talk to our Founders</td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check"></i></span></td>
                    </tr>
                    <tr>
                        <td>Delivery time</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                    </tr>

                    <tr>
                        <td></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
export default {
  components: {
    HeaderView,
    LoaderComponent,
  },
  mounted() {
  
  },
  data() {
    return {

    };
  },
  methods: {
 
  },
  computed: {

  },
};
</script>
