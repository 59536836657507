<template>
  <div class="home">
    <LoaderComponent v-show="loading" :loaderText="loaderText"/>
    <HeaderView />
    <section
      class="order-page-main-sec track-order-main-sec order-detail-main-sec agency-design-grid-right"
    >
      <div class="desktop-respovsive-main-sec">
        <div class="invoice-history-content">
          <h2 class="text-heading">My Orders</h2>
          <div class="text-1">Track all your orders and links</div>
        </div>

        <!-- Mobile Tabs Start : Only Show on Mobile Devices -->
        <div class="mobile-responsive-order-list-tabs">
          <b-card no-body class="order-card-bg">
            <b-tabs v-model="tabIndex" pills class="order-top-bar-tabs">
              <b-tab
                class="bar-chart-1"
                title="Orders"
                active
              >
                <b-card-text>
                  <div class="editor-dashboard-sec-2">
                    <div class="order-list-tabs">
                      <table>
                        <tr>
                          <template v-for="status in statuses" :key="status.id">
                            <td
                              v-if="
                                status.id === 1 ||
                                status.id === 2 ||
                                status.id === 3
                              "
                              v-bind:class="{ active: status.id === activeId }"
                              @click="activeStatus(status.id)"
                            >
                              <a :v-model="orderFilter.status"
                                ><b>{{ status.value }}</b
                                >{{ status.status }}</a
                              >
                            </td>
                          </template>
                        </tr>
                      </table>

                      <div class="order-input-field">
                        <div class="color-box">
                          <ul>
                            <!-- <li><span style="background-color: #ff004c;"></span>Premium Placement</li> -->
                            <li><span style="background-color: #d9efd8;"></span>Digital PR</li>
                            <li><span class="enterprise-bg"></span>Enterprise</li>
                          </ul>
                        </div>
                        <div
                          class="order-filter-box"
                          v-if="activeTabValue == 'orders'"
                        >
                          <div class="filter-btn" @click="openSidebar">
                            <i class="fas fa-filter"></i> Filters
                          </div>
                          <div class="download-icon-list">
                            <div @click="downloadFile()" class="top_icons">
                              <i
                                class="fas fa-file-export"
                                v-b-tooltip.hover.top="'Export CSV'"
                              ></i>
                            </div>
                            <div class="per-page-right-box">
                              <div class="select-item">
                                <select
                                  @change="changePagination()"
                                  v-model="orderFilter.per_page"
                                >
                                  <option>50</option>
                                  <option>100</option>
                                  <option>200</option>
                                  <option>500</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="filter-sideabar-box"
                            :class="{ opensideBar: isSidebarOpen }"
                          >
                            <div class="filter-form-box">
                              <div class="top-bar">
                                <div class="text-heading">Filter</div>
                                <div class="icon-box">
                                  <i
                                    class="fas fa-times"
                                    @click="closeSidebar"
                                  ></i>
                                </div>
                              </div>

                              <b-form @submit.stop.prevent>
                                <b-col md="12">
                                  <label for="text-date">Order Date</label>
                                  <Datepicker
                                    id="example-datepicker"
                                    ref="datepickerMenuOrderM"
                                    v-model="orderFilter.order_date"
                                    class="mb-2"
                                    placeholder="mm-dd-yyyy - mm-dd-yyyy"
                                    :format="dateFormat"
                                    :config="options"
                                    range
                                    :enable-time-picker="false"
                                    :min="minDate"
                                    :max="maxDate"
                                    auto-apply
                                    multi-calendars
                                    position="right"
                                    @update:modelValue="
                                      selectRangeOrder(
                                        orderFilter.order_date,
                                        'Custom Range',
                                        'M'
                                      )
                                    "
                                  >
                                    <template #left-sidebar>
                                      <div class="ranges-container">
                                        <button
                                          v-for="(value, label) in ranges"
                                          :key="label"
                                          @click="
                                            selectRangeOrder(value, label, 'M')
                                          "
                                          :class="{
                                            'range-button': true,
                                            'selected-range':
                                              isRangeSelectedOrder(
                                                value,
                                                label
                                              ) || selectedRangeOrder === label,
                                          }"
                                        >
                                          {{ label }}
                                        </button>
                                      </div>
                                    </template>
                                  </Datepicker>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-status">Status</label>
                                  <b-form-select
                                    id="input-3"
                                    v-model="orderFilter.status"
                                    :options="orderStatus"
                                    value-field="value"
                                    text-field="text"
                                    required
                                  ></b-form-select>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-order">Order ID</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="orderFilter.order_id"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-status">Order By</label>
                                  <b-form-select
                                    id="input-3"
                                    v-model="orderFilter.agencyUser"
                                    :options="agencies"
                                    required
                                  ></b-form-select>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-status">Order Type</label>
                                  <b-form-select
                                    id="input-3"
                                    v-model="orderFilter.orderType"
                                    :options="orderTypes"
                                    value-field="value"
                                    text-field="text"
                                    required
                                  ></b-form-select>
                                </b-col>
                                <b-col md="12">
                                  <div class="btn-box-list">
                                    <a
                                      href="javascript:;"
                                      class="btn-1"
                                      @click="getOrdersSearch()"
                                      >Search
                                    </a>
                                    <a
                                      href="javascript:;"
                                      class="btn-1 btn-2"
                                      @click="resetOrderFilter()"
                                      >Reset
                                    </a>
                                  </div>
                                </b-col>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="activeTabValue == 'orders'">
                      <div class="table-responsive">
                        <form>
                          <table class="addon-table">
                            <tr>
                              <th @click="sortByColumn('id')">
                                Order ID
                                <span><i class="fas fa-sort"></i></span>
                              </th>
                              <th>Order By</th>
                              <th @click="sortByColumn('created_at')">
                                Date <span><i class="fas fa-sort"></i></span>
                              </th>
                              <th @click="sortByColumn('order_title')">
                                Order Title
                                <span><i class="fas fa-sort"></i></span>
                              </th>
                              <th>Links</th>
                              <th>Addons</th>
                              <th @click="sortByColumn('total')">
                                Amount <span><i class="fas fa-sort"></i></span>
                              </th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                            <tr v-for="order in orders" :key="order.id" 
                            :class="{ 'premium-placement': order.missed_opportunity, 
                              'enterprise-bg': order.order_type == 'Enterprise', 'digitalPr-bg': order.order_type == 'Digital PR' }">
                              <td>{{ order.id }}</td>
                              <td>{{ order.order_by }}</td>
                              <td>{{ order.date }}</td>
                              <td>{{ order.order_title }}</td>
                              <td>{{ order.total_links }}</td>
                              <td>{{ order.total_addons }}</td>
                              <td>
                                {{ numberFormat(order.total, "currency") }}
                              </td>
                              <td>
                                <a
                                  class="btn-success"
                                  style="backgroundColor: #41b883; color: #fff"
                                  v-if="order.status == 'Completed'"
                                  >{{ order.status }}</a
                                >
                                <a
                                  class="btn-secondary"
                                  style="backgroundColor: #00b6cb; color: #fff"
                                  v-if="order.status == 'In Progress'"
                                  >{{ order.status }}</a
                                >
                                <a
                                  class="btn-secondary"
                                  style="backgroundColor: #9816f4; color: #fff"
                                  v-if="order.status == 'Refund'"
                                  >{{ order.status }}</a
                                >
                              </td>
                              <td>
                                <div class="btn-box">
                                  <a href="#" @click="viewLinks(order.id)">
                                    <i class="fas fa-eye"></i>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </form>
                        <div class="total-records-list">
                          <p>
                            Showing {{ rows.length }} of
                            {{ totalRecords }} records
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Links">
                <b-card-text>
                  <div class="editor-dashboard-sec-2">
                    <div class="order-list-tabs">
                      <table>
                        <tr>
                          <template v-for="status in statuses" :key="status.id">
                            <td
                              v-if="
                                status.id === 4 ||
                                status.id === 5 ||
                                status.id === 6
                              "
                              v-bind:class="{ active: status.id === activeId }"
                              @click="activeStatus(status.id)"
                            >
                              <a :v-model="orderFilter.status"
                                ><b>{{ status.value }}</b
                                >{{ status.status }}</a
                              >
                            </td>
                          </template>
                        </tr>
                      </table>

                      <div class="order-input-field">
                        <div class="color-box">
                          <ul>
                            <!-- <li><span style="background-color: #ff004c;"></span>Premium Placement</li> -->
                            <li><span style="background-color: #d9efd8;"></span>Digital PR</li>
                            <li><span class="enterprise-bg"></span>Enterprise</li>
                          </ul>
                        </div>
                        <div
                          class="order-filter-box"
                          v-if="activeTabValue == 'links'"
                        >
                          <div class="filter-btn" @click="openSidebar">
                            <i class="fas fa-filter"></i> Filters
                          </div>
                          <div class="download-icon-list">
                            <div @click="downloadLinkFile()" class="top_icons">
                              <i class="fas fa-file-export"></i>
                            </div>
                            <div class="per-page-right-box">
                              <div class="select-item">
                                <select
                                  @change="changePagination()"
                                  v-model="linkFilter.per_page"
                                >
                                  <option>50</option>
                                  <option>100</option>
                                  <option>200</option>
                                  <option>500</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div
                            class="filter-sideabar-box"
                            :class="{ opensideBar: isSidebarOpen }"
                          >
                            <div class="filter-form-box">
                              <div class="top-bar">
                                <div class="text-heading">Filter</div>
                                <div class="icon-box">
                                  <i
                                    class="fas fa-times"
                                    @click="closeSidebar"
                                  ></i>
                                </div>
                              </div>

                              <b-form @submit.stop.prevent>
                                <b-col md="12">
                                  <label for="text-status">Order Type</label>
                                  <b-form-select
                                    id="input-3"
                                    v-model="linkFilter.orderType"
                                    :options="orderTypes"
                                    value-field="value"
                                    text-field="text"
                                    required
                                  ></b-form-select>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-date">Order Date</label>
                                  <Datepicker
                                    id="example-datepicker"
                                    ref="datepickerMenuLinkM"
                                    v-model="linkFilter.order_date"
                                    class="mb-2"
                                    placeholder="mm-dd-yyyy - mm-dd-yyyy"
                                    :format="dateFormat"
                                    :config="options"
                                    range
                                    :enable-time-picker="false"
                                    :min="minDate"
                                    :max="maxDate"
                                    auto-apply
                                    multi-calendars
                                    position="right"
                                    @update:modelValue="
                                      selectRangeLink(
                                        linkFilter.order_date,
                                        'Custom Range'
                                      )
                                    "
                                  >
                                    <template #left-sidebar>
                                      <div class="ranges-container">
                                        <button
                                          v-for="(value, label) in ranges"
                                          :key="label"
                                          @click="
                                            selectRangeLink(value, label, 'M')
                                          "
                                          :class="{
                                            'range-button': true,
                                            'selected-range':
                                              isRangeSelectedLink(
                                                value,
                                                label
                                              ) || selectedRangeLink === label,
                                          }"
                                        >
                                          {{ label }}
                                        </button>
                                      </div>
                                    </template>
                                  </Datepicker>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-order">Order ID</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="linkFilter.order_id"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-link">Link ID</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="linkFilter.link_id"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-link">Live Link</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="linkFilter.live_link"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-status">Status</label>
                                  <VueMultiselect
                                      id="input-3"
                                      placeholder="Select Status"
                                      v-model="linkFilter.status"
                                      :options="linkStatuses"
                                      :close-on-select="false" 
                                      label="name" 
                                      track-by="id"
                                      :multiple="true"
                                    >
                                    <template #selection="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                                  </VueMultiselect>
                                </b-col>

                                <b-col md="12">
                                  <label for="text-domain">Destination URL</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="linkFilter.destination_link"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-anchor">Anchor Text</label>
                                  <b-form-input
                                    id="input-2"
                                    v-model="linkFilter.anchor_text"
                                    placeholder=""
                                    required
                                  ></b-form-input>
                                </b-col>
                                <b-col md="12">
                                  <label for="text-status">Order By</label>
                                  <b-form-select
                                    id="input-3"
                                    v-model="linkFilter.agency"
                                    :options="agencies"
                                    required
                                  ></b-form-select>
                                </b-col>
                                <b-col md="12">
                                  <div class="btn-box-list">
                                    <a
                                      href="javascript:;"
                                      class="btn-1"
                                      @click="getLinksSearch()"
                                      >Search
                                    </a>
                                    <a
                                      href="javascript:;"
                                      class="btn-1 btn-2"
                                      @click="resetLinkFilter()"
                                      >Reset
                                    </a>
                                  </div>
                                </b-col>
                              </b-form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-if="activeTabValue == 'links'">
                      <div class="order-detail-box links-tabs-box">
                        <div
                          class="detail-sideabar-box"
                          :class="{ opensideBar: orderSidebarOpen }"
                        >
                          <LinksDetail
                            :activeLink="activeLink"
                            :reasons="reasons"
                            @closeOrderSidebar="closeOrderSidebar"
                          >
                          </LinksDetail>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <LinksDetailTable
                          :links="links"
                          :hideOrderId="hideOrderId"
                          @openOrderSidebar="openOrderSidebar"
                        >
                        </LinksDetailTable>
                        <div class="total-records-list">
                          <p>
                            Showing {{ rows.length }} of
                            {{ totalRecords }} records
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <!-- Mobile Tabs End -->




        <div class="desktop-order-sec editor-dashboard-sec-2">
          <div class="order-list-tabs">
            <table>
              <tr>
                <td
                  v-for="status in statuses"
                  v-bind:class="{ active: status.id === activeId }"
                  :key="status.id"
                  @click="activeStatus(status.id)"
                >
                  <a :v-model="orderFilter.status"
                    ><b>{{ status.value }}</b
                    >{{ status.status }}</a
                  >
                </td>
              </tr>
            </table>
            <div class="order-input-field">
              <div class="color-box">
                <ul>
                  <!-- <li><span style="background-color: #ff004c;"></span>Premium Placement</li> -->
                  <li><span style="background-color: #d9efd8;"></span>Digital PR</li>
                  <li><span class="enterprise-bg"></span>Enterprise</li>
                </ul>
              </div>
              <div class="order-filter-box" v-if="activeTabValue == 'orders'">
                <div class="filter-btn" @click="openSidebar">
                  <i class="fas fa-filter"></i> Filters
                </div>
                <div class="download-icon-list">
                  <div @click="downloadFile()" class="top_icons">
                    <i
                      class="fas fa-file-export"
                      v-b-tooltip.hover.top="'Export CSV'"
                    ></i>
                  </div>
                  <div class="per-page-right-box">
                    <div class="select-item">
                      <select
                        @change="changePagination()"
                        v-model="orderFilter.per_page"
                      >
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  class="filter-sideabar-box"
                  :class="{ opensideBar: isSidebarOpen }"
                >
                  <div class="filter-form-box">
                    <div class="top-bar">
                      <div class="text-heading">Filter</div>
                      <div class="icon-box">
                        <i class="fas fa-times" @click="closeSidebar"></i>
                      </div>
                    </div>

                    <b-form @submit.stop.prevent>
                      <b-col md="12">
                        <label for="text-date">Order Date</label>
                        <Datepicker
                          id="example-datepicker"
                          ref="datepickerMenuOrder"
                          v-model="orderFilter.order_date"
                          class="mb-2"
                          placeholder="mm-dd-yyyy - mm-dd-yyyy"
                          :format="dateFormat"
                          :config="options"
                          range
                          :enable-time-picker="false"
                          :min="minDate"
                          :max="maxDate"
                          auto-apply
                          multi-calendars
                          position="right"
                          @update:modelValue="
                            selectRangeOrder(
                              orderFilter.order_date,
                              'Custom Range'
                            )
                          "
                        >
                          <template #left-sidebar>
                            <div class="ranges-container">
                              <button
                                v-for="(value, label) in ranges"
                                :key="label"
                                @click="selectRangeOrder(value, label)"
                                :class="{
                                  'range-button': true,
                                  'selected-range':
                                    isRangeSelectedOrder(value, label) ||
                                    selectedRangeOrder === label,
                                }"
                              >
                                {{ label }}
                              </button>
                            </div>
                          </template>
                        </Datepicker>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Status</label>
                        <b-form-select
                          id="input-3"
                          v-model="orderFilter.status"
                          :options="orderStatus"
                          value-field="value"
                          text-field="text"
                          required
                        ></b-form-select>
                      </b-col>
                      <b-col md="12">
                        <label for="text-order">Order ID</label>
                        <b-form-input
                          id="input-2"
                          v-model="orderFilter.order_id"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Order By</label>
                        <b-form-select
                          id="input-3"
                          v-model="orderFilter.agencyUser"
                          :options="agencies"
                          required
                        ></b-form-select>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Order Type</label>
                        <b-form-select
                          id="input-3"
                          v-model="orderFilter.orderType"
                          :options="orderTypes"
                          value-field="value"
                          text-field="text"
                          required
                        ></b-form-select>
                      </b-col>
                    
                      <b-col md="12">
                        <div class="btn-box-list">
                          <a
                            href="javascript:;"
                            class="btn-1"
                            @click="getOrdersSearch()"
                            >Search
                          </a>
                          <a
                            href="javascript:;"
                            class="btn-1 btn-2"
                            @click="resetOrderFilter()"
                            >Reset
                          </a>
                        </div>
                      </b-col>
                    </b-form>
                  </div>
                </div>
              </div>
              <div class="order-filter-box" v-if="activeTabValue == 'links'">
                <div class="filter-btn" @click="openSidebar">
                  <i class="fas fa-filter"></i> Filters
                </div>
                <div class="download-icon-list">
                  <div @click="downloadLinkFile()" class="top_icons">
                    <i class="fas fa-file-export"></i>
                  </div>
                  <div class="per-page-right-box">
                    <div class="select-item">
                      <select
                        @change="changePagination()"
                        v-model="linkFilter.per_page"
                      >
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>500</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  class="filter-sideabar-box"
                  :class="{ opensideBar: isSidebarOpen }"
                >
                  <div class="filter-form-box">
                    <div class="top-bar">
                      <div class="text-heading">Filter</div>
                      <div class="icon-box">
                        <i class="fas fa-times" @click="closeSidebar"></i>
                      </div>
                    </div>

                    <b-form @submit.stop.prevent>
                      <b-col md="12">
                        <label for="text-status">Order Type</label>
                        <b-form-select
                          id="input-3"
                          v-model="linkFilter.orderType"
                          :options="orderTypes"
                          value-field="value"
                          text-field="text"
                          required
                        ></b-form-select>
                      </b-col>
                      <b-col md="12">
                        <label for="text-date">Order Date</label>
                        <Datepicker
                          id="example-datepicker"
                          ref="datepickerMenuLink"
                          v-model="linkFilter.order_date"
                          class="mb-2"
                          placeholder="mm-dd-yyyy - mm-dd-yyyy"
                          :format="dateFormat"
                          :config="options"
                          range
                          :enable-time-picker="false"
                          :min="minDate"
                          :max="maxDate"
                          auto-apply
                          multi-calendars
                          position="right"
                          @update:modelValue="
                            selectRangeLink(
                              linkFilter.order_date,
                              'Custom Range'
                            )
                          "
                        >
                          <template #left-sidebar>
                            <div class="ranges-container">
                              <button
                                v-for="(value, label) in ranges"
                                :key="label"
                                @click="selectRangeLink(value, label)"
                                :class="{
                                  'range-button': true,
                                  'selected-range':
                                    isRangeSelectedLink(value, label) ||
                                    selectedRangeLink === label,
                                }"
                              >
                                {{ label }}
                              </button>
                            </div>
                          </template>
                        </Datepicker>
                      </b-col>
                      <b-col md="12">
                        <label for="text-order">Order ID</label>
                        <b-form-input
                          id="input-2"
                          v-model="linkFilter.order_id"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-link">Link ID</label>
                        <b-form-input
                          id="input-2"
                          v-model="linkFilter.link_id"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-link">Live Link</label>
                        <b-form-input
                          id="input-2"
                          v-model="linkFilter.live_link"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Status</label>
                        <VueMultiselect
                          id="input-3"
                          placeholder="Select Status"
                          v-model="linkFilter.status"
                          :options="linkStatuses"
                          :close-on-select="false" 
                          :clear-on-select="false" 
                          label="name" 
                          track-by="id"
                          :multiple="true"
                        >
                        <template #selection="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                        </VueMultiselect>
                      </b-col>

                      <b-col md="12">
                        <label for="text-domain">Destination URL</label>
                        <b-form-input
                          id="input-2"
                          v-model="linkFilter.destination_link"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>

                      <!-- <b-col md="12">
                        <label for="text-primary">Primary Category</label>
                        <b-form-select
                          id="input-3"
                          v-model="linkFilter.primary_category"
                          :options="primaryCategoryList"
                          required
                        ></b-form-select>
                      </b-col> -->
                      <b-col md="12">
                        <label for="text-anchor">Anchor Text</label>
                        <b-form-input
                          id="input-2"
                          v-model="linkFilter.anchor_text"
                          placeholder=""
                          required
                        ></b-form-input>
                      </b-col>
                      <b-col md="12">
                        <label for="text-status">Order By</label>
                        <b-form-select
                          id="input-3"
                          v-model="linkFilter.agency"
                          :options="agencies"
                          required
                        ></b-form-select>
                      </b-col>
                      <b-col md="12">
                        <div class="btn-box-list">
                          <a
                            href="javascript:;"
                            class="btn-1"
                            @click="getLinksSearch()"
                            >Search
                          </a>
                          <a
                            href="javascript:;"
                            class="btn-1 btn-2"
                            @click="resetLinkFilter()"
                            >Reset
                          </a>
                        </div>
                      </b-col>
                    </b-form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="activeTabValue == 'orders'">
            <div class="table-responsive">
              <form>
                <table class="addon-table">
                  <tr>
                    <th @click="sortByColumn('id')">
                      Order ID <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th>Order By</th>
                    <th @click="sortByColumn('created_at')">
                      Date <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('order_title')">
                      Order Title <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th>Links</th>
                    <th>Addons</th>
                    <th @click="sortByColumn('total')">
                      Amount <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="order in orders" :key="order.id" 
                  :class="{ 'premium-placement': order.missed_opportunity, 
                  'enterprise-bg': order.order_type == 'Enterprise', 'digitalPr-bg': order.order_type == 'Digital PR' }">
                    <td>{{ order.id }}</td>
                    <td>{{ order.order_by }}</td>
                    <td>{{ order.date }}</td>
                    <td>{{ order.order_title }}</td>
                    <td>{{ order.total_links }}</td>
                    <td>{{ order.total_addons }}</td>
                    <td>{{ numberFormat(order.total, "currency") }}</td>
                    <td>
                      <a
                        class="btn-success"
                        style="backgroundColor: #41b883; color: #fff"
                        v-if="order.status == 'Completed'"
                        >{{ order.status }}</a
                      >
                      <a
                        class="btn-secondary"
                        style="backgroundColor: #00b6cb; color: #fff"
                        v-if="order.status == 'In Progress'"
                        >{{ order.status }}</a
                      >
                      <a
                        class="btn-secondary"
                        style="backgroundColor: #9816f4; color: #fff"
                        v-if="order.status == 'Refund'"
                        >{{ order.status }}</a
                      >
                    </td>
                    <td>
                      <div class="btn-box">
                        <!-- <router-link to="/order-detail/"
                          ><i class="fas fa-eye"></i
                        ></router-link> -->
                        <a href="#" @click="viewLinks(order.id)">
                          <i class="fas fa-eye"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </table>
              </form>
              <div class="total-records-list">
                <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
              </div>
            </div>
          </template>
          <template v-if="activeTabValue == 'links'">
            <div class="order-detail-box">
              <div
                class="detail-sideabar-box"
                :class="{ opensideBar: orderSidebarOpen }"
              >
                <LinksDetail
                  :activeLink="activeLink"
                  :reasons="reasons"
                  @closeOrderSidebar="closeOrderSidebar"
                >
                </LinksDetail>
              </div>
            </div>
            <div class="table-responsive">
              <LinksDetailTable
                :links="links"
                :hideOrderId="hideOrderId"
                @openOrderSidebar="openOrderSidebar"
              >
              </LinksDetailTable>
              <div class="total-records-list">
                <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="pagination-list" v-if="activeTabValue == 'links'">
        <paginate
          :click-handler="getLinks"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="linkFilter.last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="linkFilter.length"
          v-model="linkFilter.page"
          :per-page="linkFilter.per_page"
        >
        </paginate>
      </div>
      <div class="pagination-list" v-if="activeTabValue == 'orders'">
        <paginate
          :click-handler="getOrders"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="orderFilter.last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="orderFilter.length"
          v-model="orderFilter.page"
          :per-page="orderFilter.per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<style></style>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LinksDetailTable from "@/components/orderLinksComponents/OrderDetailComponent.vue";
import LinksDetail from "@/components/orderLinksComponents/LinkDetailComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { numberFormat } from "@/prod.js";
import Paginate from "vuejs-paginate-next";
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: "OrderView",
  components: {
    HeaderView,
    Datepicker,
    LinksDetailTable,
    LinksDetail,
    LoaderComponent,
    Paginate,
    VueMultiselect
  },
  async mounted() {
    await this.getOrders();
    await this.getTabsDetail();
    await this.LinksStatus();
    var url = window.location.pathname;
    var orderParam = url.substring(url.lastIndexOf("/") + 1);
    if (parseInt(orderParam)) {
      this.activeId = 4;
      this.linkFilter.link_id = orderParam;
      this.activeStatus(4);
      await this.getLinks();
      if (!this.links[0]) {
        this.$router.push("/dashboard");
      } else {
        this.openOrderSidebar(this.links[0]);
      }
    } else if (orderParam == "writing") {
      this.activeStatus(4);
    } else if (orderParam == "pending-review") {
      this.activeStatus(4);
    } else if (orderParam == "delivered") {
      this.activeStatus(4);
    } else if (orderParam == "submission") {
      this.activeStatus(4);
    } else {
      this.linkFilter.status = "";
    }
    await this.agenciesUsers();
    await this.CategoryList();
  },
  data() {
    return {
      numberFormat,
      loading: false,
      loaderText: '',
      tabIndex: 0,
      search: {
        all_invoice: "",
        order_status: "",
      },
      filter: {
        order_date: "",
        link: "",
        domain: "",
        statusSelect: "",
        orderId: "",
        anchorText: "",
        linkId: "",
      },
      activeId: 1,
      statusValue: "orders",
      linksValue: "links",
      statuses: [
        { id: 1, value: "", status: "Total Orders" },
        { id: 2, value: "", status: "Orders Completed" },
        { id: 3, value: "", status: "Orders Pending" },
        { id: 4, value: "", status: "Total Links" },
        { id: 5, value: "", status: "Links Pending" },
        { id: 6, value: "", status: "Links Delivered" },
      ],
      date: new Date(),
      dateFormat: "MM-dd-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      orderFilter: {
        page: 1,
        per_page: 50,
        last_page: 1,
        s: "",
        e: "",
        order_date: "",
        addon: [],
        agency: "",
        user_id: null,
        status: null,
        dr_order: null,
        order_id: "",
        content: null,
        columnTo: "id",
        orderBy: "desc",
        agencyUser: null,
        orderDownload: 'no',
        orderType: null
      },
      paginationData: {},
      paginationDataLinks: {},
      linkFilterStatus: [],
      linkFilter: {
        page: 1,
        per_page: 50,
        last_page: 1,
        s: "",
        e: "",
        order_date: "",
        order_id: "",
        link_id: "",
        live_link: "",
        anchor_text: "",
        destination_link: "",
        primary_category: null,
        status: "",
        content: null,
        columnTo: "id",
        orderBy: "desc",
        agency: null,
        linkDownload: 'no',
        orderType: null
      },
      orderStatus: [
        { text: "Select Status", value: null },
        { text: "Completed", value: "Completed" },
        { text: "In Progress", value: "Pending" },
      ],
      orderTypes: [
        { text: "Select Order Type", value: null },
        { text: "Enterprise", value: "Enterprise" },
        { text: "Digital PR", value: "Digital PR" },
        // { text: "Premium Placement", value: "missed_opportunity" },
      ],
      isSidebarOpen: false,
      isSidebarClose: true,
      orderSidebarOpen: false,
      orderSidebarClose: true,
      activeLink: "",
      activeTabValue: "orders",
      orders: [],
      orders_detail: [],
      links: [],
      reasons: [],
      linkStatuses: [],
      agencies: [{ text: "Select Order By", value: null }],
      primaryCategoryList: [{ text: "Select Category", value: null }],
      hideOrderId: false,
      rows: [],
      totalRecords: 0,
      selectedRangeOrder: "",
      selectedRangeLink: "",
      ranges: {
        Today: [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        Yesterday: [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            )
          ),
        ],
        "Last 7 Days": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Last 30 Days": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 30
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "This Month": [
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Last Month": [
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          ),
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth(), 0)
          ),
        ],
        "This Year": [
          new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Custom Range": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
      },
    };
  },
  methods: {
    selectRangeOrder(value, label, screen) {
      this.orderFilter.order_date = value;
      this.selectedRangeOrder =
        label == "" || label == "Custom Range" ? "Custom Range" : "";
      console.log(screen);
      if (screen == "M") {
        this.$refs.datepickerMenuOrderM.closeMenu();
      } else {
        this.$refs.datepickerMenuOrder.closeMenu();
      }
    },
    isRangeSelectedOrder(value) {
      return (
        value === this.orderFilter.order_date ||
        this.selectedRangeOrder === value
      );
    },
    selectRangeLink(value, label, screen) {
      this.linkFilter.order_date = value;
      this.selectedRangeLink =
        label == "" || label == "Custom Range" ? "Custom Range" : "";
      if (screen == "M") {
        this.$refs.datepickerMenuLinkM.closeMenu();
      } else {
        this.$refs.datepickerMenuLink.closeMenu();
      }
    },
    isRangeSelectedLink(value) {
      return (
        value === this.linkFilter.order_date || this.selectedRangeLink === value
      );
    },
    searchWithOrderStatus() {
      this.$nextTick(function () {
        this.getOrders();
      });
    },
    searchWithLinkStatus() {
      this.$nextTick(function () {
        this.getLinks();
      });
    },
    async getOrders() {
      this.loading = true;
      this.orderFilter.s = this.orderFilter.order_date
        ? this.orderFilter.order_date[0]
        : "";
      this.orderFilter.e = this.orderFilter.order_date
        ? this.orderFilter.order_date[1]
        : "";
        this.orderFilter.orderDownload = 'no';
      try {
        let res = await this.$store.dispatch(
          "Product/getOrdersDataAction",
          this.orderFilter
        );
        this.orders = res.orders;
        this.rows = res.orders;
        this.totalRecords = res.meta.total;
        this.paginationData = res;
        this.orderFilter.page = res.meta.current_page;
        this.orderFilter.per_page = res.meta.per_page;
        this.orderFilter.last_page = res.meta.last_page;
        this.orderFilter.length = res.meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getTabsDetail() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Product/getTabsDetailDataAction"
        );
        this.orders_detail = res;
        this.statuses[0].value = this.orders_detail.total_orders;
        this.statuses[1].value = this.orders_detail.orders_completed;
        this.statuses[2].value = this.orders_detail.pending_orders;
        this.statuses[3].value = this.orders_detail.total_links;
        this.statuses[4].value = this.orders_detail.pending_links;
        this.statuses[5].value = this.orders_detail.link_delivered;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getLinks() {
      this.loading = true;
      this.linkFilter.s = this.linkFilter.order_date
        ? this.linkFilter.order_date[0]
        : "";
      this.linkFilter.e = this.linkFilter.order_date
        ? this.linkFilter.order_date[1]
        : "";
      this.linkFilter.linkDownload = 'no';
      try {
        let res = await this.$store.dispatch(
          "Link/getAllLinksDataAction",
          this.linkFilter
        );
        this.links = res.data.links;
        this.rows = res.data.links;
        this.totalRecords = res.meta.total;
        this.reasons = res.reasons;
        this.paginationDataLinks = res.data.links;
        this.linkFilter.page = res.meta.current_page;
        this.linkFilter.per_page = res.meta.per_page;
        this.linkFilter.last_page = res.meta.last_page;
        this.linkFilter.length = res.meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async LinksStatus() {
      try {
        let res = await this.$store.dispatch("Link/getAllLinkStatusDataAction");
        res.forEach((item) => {
          this.linkStatuses.push({ id: item.id, name: item.status });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    async agenciesUsers() {
      try {
        let res = await this.$store.dispatch("Link/getAgenciesUsersDataAction");
        res.agencies.forEach((item) => {
          this.agencies.push({ text: item.name, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    async CategoryList() {
      try {
        let res = await this.$store.dispatch("Link/getAllCategoryDataAction");
        res.forEach((item) => {
          this.primaryCategoryList.push({ text: item.name, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    async activeStatus(id) {
      this.activeId = id;
      this.orderFilter.status = null;
      this.linkFilter.status = "";
      if (this.activeId == 1) {
        this.activeTabValue = "orders";
        this.orderFilter.page = 1;
        this.orderFilter.status = "";
        await this.getOrders();
      } else if (this.activeId == 2) {
        this.activeTabValue = "orders";
        this.orderFilter.page = 1;
        this.orderFilter.status = "Completed";
        await this.getOrders();
      } else if (this.activeId == 3) {
        this.activeTabValue = "orders";
        this.orderFilter.page = 1;
        this.orderFilter.status = "Pending";
        await this.getOrders();
      }
      if (this.activeId == 4) {
        this.activeTabValue = "links";
        this.linkFilter.page = 1;
        var url = window.location.pathname;
        var orderParam = url.substring(url.lastIndexOf("/") + 1);
        if (orderParam == "writing") {
        this.setStatusIdDynamically([12]);
        } else if (orderParam == "pending-review") {
        this.setStatusIdDynamically([19]);
        } else if (orderParam == "delivered") {
        this.setStatusIdDynamically([14]);
        } else if (orderParam == "submission") {
        this.setStatusIdDynamically([13]);
        } else {
          this.linkFilter.status = "";
        }
        await this.getLinks();
      } else if (this.activeId == 5) {
        this.activeTabValue = "links";
        this.linkFilter.page = 1;
        this.setStatusIdDynamically([10,11,12,13,16,19]);
        await this.getLinks();
      } else if (this.activeId == 6) {
        this.activeTabValue = "links";
        this.linkFilter.page = 1;
        this.setStatusIdDynamically([14]);
        await this.getLinks();
      } 
      // else {
      //   this.orderFilter.status = null;
      //   this.linkFilter.status = null;
      // }
    },
    setStatusIdDynamically(id){
      const dynamicSelectedIds = id;
      const dynamicSelectedItems = this.linkStatuses.filter(option => dynamicSelectedIds.includes(option.id));
      this.linkFilter.status = dynamicSelectedItems;
    },
    viewLinks(orderId) {
      localStorage.removeItem("order_id");
      localStorage.setItem("order_id", orderId);
      this.$router.push("/order-detail");
    },

    openSidebar() {
      setTimeout(() => (this.isSidebarOpen = true), 500);
      setTimeout(() => (this.isSidebarClose = false), 500);
    },

    closeSidebar() {
      setTimeout(() => (this.isSidebarOpen = false), 500);
    },

    openOrderSidebar(linksDetail) {
      this.activeLink = linksDetail;
      setTimeout(() => (this.orderSidebarOpen = true), 500);
      setTimeout(() => (this.orderSidebarClose = false), 500);
    },

    closeOrderSidebar() {
      setTimeout(() => (this.orderSidebarOpen = false), 500);
    },

    resetOrderFilter() {
      this.closeSidebar();
      this.activeStatus(1);
      this.orderFilter.order_date = "";
      this.orderFilter.status = null;
      this.orderFilter.order_id = "";
      this.orderFilter.agencyUser = null;
      this.orderFilter.orderType = null;
      this.getOrders();
    },

    resetLinkFilter() {
      this.closeSidebar();
      this.activeStatus(4);
      this.linkFilter.order_date = "";
      this.linkFilter.order_id = "";
      this.linkFilter.link_id = "";
      this.linkFilter.live_link = "";
      this.linkFilter.anchor_text = "";
      this.linkFilter.destination_link = "";
      this.linkFilter.primary_category = null;
      this.linkFilter.status = "";
      this.linkFilter.agency = null;
      this.linkFilter.orderType = null;
      this.getLinks();
    },

    async downloadFile() {
      this.loaderText = 'Please wait while we are processing your request';
      this.loading = true;
      this.orderFilter.orderDownload = 'yes';
      try {
        const response = await this.$store.dispatch(
          "Product/getOrdersDataAction",
          this.orderFilter
        );
        let blob = new Blob([response], {
          type: "application/csv",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Order.csv';
        link.click();
        this.loaderText = '';
        this.loading = false;
      } catch (e) {
        this.loaderText = '';
        this.loading = false;
      }
    },

    sortByColumn(column) {
      this.orderFilter.orderBy =
        this.orderFilter.orderBy == "asc" ? "desc" : "asc";
      this.getSortedResults(column, this.orderFilter.orderBy);
    },

    getSortedResults(column, order) {
      if (this.activeTabValue == "orders") {
        this.orderFilter.columnTo = column;
        this.orderFilter.orderBy = order;
        this.getOrders();
      } else if (this.activeTabValue == "links") {
        this.linkFilter.columnTo = column;
        this.linkFilter.orderBy = order;
        this.getLinks();
      }
    },

    changePagination() {
      if (this.activeTabValue == "orders") {
        this.orderFilter.page = 1;
        this.getOrders();
      } else if (this.activeTabValue == "links") {
        this.linkFilter.page = 1;
        this.getLinks();
      }
    },

    getOrdersSearch() {
      this.closeSidebar();
      this.orderFilter.page = 1;
      this.getOrders();
    },

    getLinksSearch() {
      this.closeSidebar();
      this.linkFilter.page = 1;
      this.getLinks();
    },

    async downloadLinkFile() {
      this.loaderText = 'Please wait while we are processing your request';
      this.loading = true;
      this.linkFilter.linkDownload = 'yes';
      try {
        const response = await this.$store.dispatch( 
          "Link/getAllLinksDataAction",
          this.linkFilter
        );
        let blob = new Blob([response], {
          type: "application/csv",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Links.csv';
        link.click();
        this.loaderText = '';
        this.loading = false;
      } catch (e) {
        this.loaderText = '';
        this.loading = false;
      }
    },
  },
  watch: {
    $route(to) {
      if (to.path === "/order") {
        this.linkFilter.link_id = "";
        this.closeOrderSidebar();
        this.getOrders();
        this.LinksStatus();
        this.CategoryList();
        this.activeStatus(1);
      }
    },
    tabIndex(val) {
      if(val == 0) {
          this.activeId = 1;
          this.activeStatus(1);
      }
      if(val == 1) {
          this.activeId = 4;
          this.activeStatus(4);
      }
    },
  },
};
</script>