<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
  <section class="agency-signUp-main-sec">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-7">
          <div class="resend-box">
            <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" /></a>

            <div class="text-heading">Verify your email address</div>
            <p>
              You are almost there! We have sent a verification link to your
              email address.
            </p>
            <p>
              Just click on the link in the email to complete your signup. You
              may need <br />
              to check your spam folder if you do not see it in your inbox.
            </p>
            <p>Still can not find the email? Please click below to resend!</p>
            <router-link to="#" class="btn-1" @click="resendEmail"
              >Resend Email
              <b-spinner small v-show="loading"></b-spinner>
              </router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<style>
</style>

<script>
import LoaderComponent from "@/components/LoaderComponent.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "emailVerificationView",
  components: {
    LoaderComponent,
  },
  mounted() {
    this.checkUrlParams();

    if (this.$route.query.verification_code) {
      this.data.verification_code = this.$route.query.verification_code;
      this.verification(this.data.verification_code);
    }
  },
  data() {
    return {
      loading: false,
      data: {
        verification_code: "",
      },
      resendEmailData: {
        email: "",
        utm_source: "direct",
        utm_medium: "direct",
        utm_campaign: "direct",
        da: "",
        category: "",
      },
      errors: [],
      queryParam: false,
      queryParamValue: '',
    };
  },

  methods: {
    getQueryParameterValue(parameterName) {
      var queryString = location.search.substring(1);
      var queryParams = queryString.split("&");

      for (var i = 0; i < queryParams.length; i++) {
        var param = queryParams[i].split("=");
        if (param[0] === parameterName) {
          return decodeURIComponent(param[1]);
        }
      }
      return null;
    },
    checkUrlParams() {
      var daValue = this.getQueryParameterValue("da");
      var categoryValue = this.getQueryParameterValue("category");
      var sourceValue = this.getQueryParameterValue("utm_source");
      var mediumValue = this.getQueryParameterValue("utm_medium");
      var campaignValue = this.getQueryParameterValue("utm_campaign");

      if (daValue !== null && categoryValue !== null) {
        this.resendEmailData.da = daValue;
        this.resendEmailData.category = categoryValue;
        this.queryParamValue = `?da=${daValue}&category=${categoryValue}`;
        if(sourceValue){
          this.resendEmailData.utm_source = sourceValue;
          this.queryParamValue += `&utm_source=${sourceValue}`;
        }
        if(mediumValue){
          this.resendEmailData.utm_medium = mediumValue;
          this.queryParamValue += `&utm_medium=${mediumValue}`;
        }
        if(campaignValue){
          this.resendEmailData.utm_campaign = campaignValue;
          this.queryParamValue += `&utm_campaign=${campaignValue}`;
        }
        this.queryParam = true;
      }
    },
   async verification(verification_code) {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Auth/isEmailVerifiedAction",
          {
            'verification_code' : verification_code,
          },
          {
            root: true,
          }
        );

        if (res.success) {
          this.loading = false;
          localStorage.removeItem("email");
          if(this.queryParam){
            this.$router.push(`/product${this.queryParamValue}`);
          }else{
            this.$router.push("/dashboard");
          }
        }else{
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (error) {
          this.loading = false;
          toast.error(error.response.data.errors, { timeout: 3000 });
          this.errors = error.response.data.errors;
      }
    },
    async resendEmail() {
      this.resendEmailData.email = localStorage.getItem("email");
      if(this.resendEmailData.email){
        try {
          this.loading = true;
          let res = await this.$store.dispatch(
            "Auth/resendEmailForVerificationAction", this.resendEmailData, {
              root: true,
            }
          );

          if (res.success) {
            this.loading = false;
            toast.success(res.message, { timeout: 3000 });
            // this.$router.push("/email-verification");
          }
        } catch (error) {
          this.loading = false;
          toast.error(error.response.data.errors, { timeout: 3000 });
          this.errors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
