import { DashboardServices } from '@/services/dashboardServices';
export default {
    namespaced: true,

    actions:{

        async getAllDashboardDataAction (_, payload){
            let res = await DashboardServices.getAllDashboardDataAPI(payload);
            let data = res.data;
            return data;
        },   

        async getContentApprovelrDataAction (){
            let res = await DashboardServices.getContentApprovelAPI();
            let data = res.data;
            return data;
        },  

        async getLiveLinksDataAction (){
            let res = await DashboardServices.getLiveLinkDataAPI();
            let data = res.data;
            return data;
        },  

        async getRecentMessageDataAction (){
            let res = await DashboardServices.getRecentMessageAPI();
            let data = res.data;
            return data;
        },  

        async messageReadDataAction(_, payload) {
            let res = await DashboardServices.messageReadAPI(payload);
            let data = res.data;
            return data;
        },  

        async getDashboardReportsDataAction (_, payload){
            let res = await DashboardServices.getDashboardReportsAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getDAReportsDataAction (_, payload){
            let res = await DashboardServices.getDAdReportsAPI(payload);
            let data = res.data;
            return data;
        },

        async getDRReportsDataAction (_, payload){
            let res = await DashboardServices.getDRdReportsAPI(payload);
            let data = res.data;
            return data;
        },

        async getDomainReportsDataAction (_, payload){
            let res = await DashboardServices.getDomainReportsAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getProductDataAction (){
            let res = await DashboardServices.getProductAPI();
            let data = res.data;
            return data;
        }, 

        async getDomainListDataAction(){
            let res = await DashboardServices.getDomainListAPI();
            let data = res.data;
            return data;
        },
        
        async analyticsDataAction(_, payload) {
            let res = await DashboardServices.analyticsDataAPI(payload);
            let data = res.data;
            return data;
        },
    }
}