<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec user-management"
    >
    <div class="packages-heading">
            <h2 class="mc-b-1">Check Out our Packages</h2>
            <p>Enjoy competitive rates and peace of mind&nbsp;</p>
        </div>
    <div class="table-responsive">
            <table class="table pricing-table">
              <thead>
                    <tr>
                        <th></th>
                        <th>Starter</th>
                        <th>Booster</th>
                        <th>Amplifier</th>
                        <th>Game Changer</th>
                    </tr>
                </thead>
                <tbody>
              
                  <tr>
                        <td></td>
                        <td>
                            <div class="pricing">
                                $3,500 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $6,400 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $10,000 <span>/month</span>
                            </div>
                        </td>
                        <td>
                            <div class="pricing">
                                $13,500 <span>/month</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Number of Links</td>
                        <td>5 to 10 Links</td>
                        <td>10 to 20 Links</td>
                        <td>20 to 30 Links</td>
                        <td>30 to 40 Links</td>
                    </tr>
                    <tr>
                        <td>Domain Authority</td>
                        <td>40 to 90+</td>
                        <td>40 to 90+</td>
                        <td>40 to 90+</td>
                        <td>40 to 90+</td>
                    </tr>
                    <tr>
                        <td>Indexable Content</td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                    </tr>
                    <tr>
                        <td>In-Content Links</td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                    </tr>
                    <tr>
                        <td>Websites with upto 100K Traffic</td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                        <td><span class="check-icon"><i class="fas fa-check" aria-hidden="true"></i></span></td>
                    </tr>
                    <tr>
                        <td>Unique Stories</td>
                        <td>5 to 10</td>
                        <td>10 to 20</td>
                        <td>20 to 30</td>
                        <td>30 to 40</td>
                    </tr>
                    <tr>
                        <td>Delivery time</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                        <td>30 Days</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                        <td><a href="https://calendly.com/linkbuildinghq/linkbuildinghq" target="_blank" class="primary-btn dark-transparent-bg">Schedule a call</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
export default {
  components: {
    HeaderView,
    LoaderComponent,
  },
  mounted() {
  
  },
  data() {
    return {

    };
  },
  methods: {
 
  },
  computed: {

  },
};
</script>
